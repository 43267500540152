<template>
  <vx-card class="user-profile-page"
           :class="{'main-box top-zero-radius': !termId, 'no-box-shadow': termId > 0}"
           v-if="term && Object.keys(term).length > 0">

    <profile-main-box :profile="getTermProfile"
                      :default-action="profileStatus"
                      :actionsList="actionsList"
                      @editTerm="handleChangeProfileStatus('editTerm')"
                      @termPriceList="handleChangeProfileStatus('termPriceList')"
                      @termActivitiesLog="handleChangeProfileStatus('termActivitiesLog')">

      <terms-price-list v-if="profileStatus === 'termPriceList'" :term-id="termId || parseInt($route.params.id)"/>

      <term-activities-log v-if="profileStatus === 'termActivitiesLog'" :term-id="termId || parseInt($route.params.id)" />

      <edit-term v-if="profileStatus === 'editTerm'" :term-id="termId || parseInt($route.params.id)" />
    </profile-main-box>
  </vx-card>
</template>

<script>
import ProfileMainBox from '@/components/profileMainBox/profileMainBox2.vue'
import CustomIcon from '../../../../../components/customIcon/customIcon.vue'
import TermsPriceList from "../price/list/termsPriceList.vue";
import EditTerm from "@/views/admin/club/terms/edit/editTerm.vue";
import TermActivitiesLog from "@/views/admin/club/terms/activitiesLog/termActivitiesLog.vue";
import {getTerm} from '@/http/requests/club/terms'
import {checkUserPermissions, getAvatarUrl} from '@/assets/js/functions'

export default {
  name: 'TermProfile',
  components: {TermActivitiesLog, EditTerm, TermsPriceList, CustomIcon, ProfileMainBox},
  props: {
    termId: {
      type: [Number, String],
      default: 0
    }
  },
  metaInfo() {
    return {
      title: this.term ? this.$t('terms.profile.dynamicTitle', {name: this.term.general.name.value}) : this.$t('terms.profile.title')
    }
  },
  data() {
    return {
      profileStatus: 'termPriceList',
      actions: {
        toolbar: [
          {
            id: {name: 'editTerm', params: {id: this.$route.params.id}},
            type: 'link',
            icon: 'EDIT',
            iconPack: 'useral',
            color: 'warning',
            permission: 'term.update'
          }
        ],
        leftToolbar: [
          {
            id: 'routeBackBTN',
            route: {name: 'terms'},
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      },
      term: null,
      actionsList: [
        {
          event: 'editTerm',
          name: 'editTerm',
          header: '',
          permission: 'term.update',
          i18n: 'terms.profile.actions.edit',
          icon: 'EDIT',
          iconPack: 'useral'
        },
        {
          event: 'termPriceList',
          name: 'termPriceList',
          header: '',
          permission: 'term.update',
          i18n: 'terms.profile.actions.price',
          icon: 'DOLLAR_SIGN',
          iconPack: 'useral'
        },
        {
          event: 'termActivitiesLog',
          name: 'termActivitiesLog',
          params: {id: this.termId || this.$route.params.id},
          header: this.$t('terms.profile.actions.logs'),
          i18n: '',
          icon: 'LAST_ACTIVITIES',
          iconPack: 'useral',
          permission: 'activity_logs.show'
        }
      ]
    }
  },
  created() {
    //check permissions
    if (!checkUserPermissions('term.update')) {
      delete this.actions.toolbar
    }

    if (!this.termId) {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
      }, 50)
    }

    this.getTerm()
  },
  computed: {
    getTermProfile() {
      const term = {
        avatar: this.term.avatar ? getAvatarUrl(this.term.avatar) : '',
        name: `${this.term.general.name.value}`,
        general: this.term.general,
        importantData: [
          {
            value: `${this.term.general.startTime} ${this.$t('terms.profile.asiderAvatar.until')} ${this.term.general.endTime}`,
            key: this.$t('terms.profile.asiderAvatar.term')
          }
        ],
        details: []
      }

      return term
    }
  },
  methods: {
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    getTerm() {

      getTerm(this.termId || this.$route.params.id).then(res => {
        const term = res.data.data
        this.term = {
          general: {
            name: {
              value: term.name,
              isInvalid: ''
            },
            startTime: term.start_time || '',
            endTime: term.end_time || ''
          },
          avatar: term.avatar || ''
        }

        if (!this.termId) {
          this.$store.dispatch('setPageTitle', this.$t('terms.profile.dynamicTitle', {name: this.term.general.name.value}))
        }
      })
    },
    handleChangeProfileStatus(status) {
      if (status !== this.profileStatus) {
        this.profileStatus = ''
        this.$nextTick(() => {
          this.profileStatus = status
        })
      }
    },
    handleClick(id) {
      document.getElementById(id).click()
    },
    printPage() {
      window.print()
    }
  }
}
</script>

<style lang="scss">
.user-profile-page {

  .vx-card__body {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media print {
  .con-vs-dialog.very-big-prompt {
    .vs-dialog {
      height: 99% !important;
      width: 99% !important;
      max-width: 99% !important;
    }
  }
}

@page {
  margin: 0.5mm;
}
</style>
