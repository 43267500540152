import axios from '@/http/axios'
import {createCancelTokenHandler} from "../../axios/utils";

const cancelTokenHandlerObject = {
    getTerms: createCancelTokenHandler('getTerms'),
    getTermsTrash: createCancelTokenHandler('getTermsTrash'),
    getTermActivitiesLog: createCancelTokenHandler('getTermActivitiesLog')
}

function makeQuery (page = 1, filters = [], sorts = []) {
    let queries = []

    if (page > 1) queries.push(`page=${page}`)

    if (filters.length) queries = queries.concat(...filters)

    if (sorts.length) queries = queries.concat(...sorts)

    return queries
}

export function getTerms (page, filters = [], sorts = []) {
    let queries = ''
    if (page > 1) {
        queries += `page=${page}`
    }

    if (filters.length) {
        if (queries.length > 0) queries += '&'

        queries += filters.join('&')
    }

    if (sorts.length) {
        if (queries.length > 0) queries += '&'

        queries += sorts.join('&')
    }

    if (queries.length > 0) queries = `?${  queries}`

    return axios({
        url: `v1/admin/club/terms${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getTerms'].handleRequestCancellation().token
    })
}

export function getTerm (id) {
    return axios({
        url: `v1/admin/club/terms/${id}`,
        method: 'get'
    })
}

export function getTermsTrash (page = 1, filters = [], sorts = []) {

    let queries = makeQuery(page, filters, sorts)

    queries = queries.length > 0 ? `&${  queries.join('&')}` : ''

    return axios({
        url: `v1/admin/club/terms?trashed=true${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getTermsTrash'].handleRequestCancellation().token
    })
}

export function getTermActivitiesLog (id, page, filters = [], sorts = []) {
    let queries = makeQuery(page, filters, sorts)

    if (queries.length > 0) queries = `?${  queries.join('&')}`
    return axios({
        url: `v1/admin/club/terms/${id}/activity-logs${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getTermActivitiesLog'].handleRequestCancellation().token
    })
}

export function insertTerm (payment) {
    return axios({
        url: 'v1/admin/club/terms',
        data: payment,
        method: 'post'
    })
}

export function editTerm (id, payment) {
    return axios.patch(`v1/admin/club/terms/${id}`, payment)
}

export function deleteTerm (id, force = false) {
    return axios({
        url: `v1/admin/club/terms/${id}${force ? '/force' : ''}`,
        method: 'delete'
    })
}

export function restoreTerm (id) {
    return axios({
        url: `v1/admin/club/terms/${id}/restore`,
        method: 'put'
    })
}
